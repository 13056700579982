import { create } from "zustand"
import { devtools, combine } from "zustand/middleware"

//APIs
import { PublishersToken } from "@/api/common.api"

type User = {
  id?: string
  metadata?: Record<string, any>
}

const initialState = {
  title: "TrueMoney Coin",
  isEnableNavbar: true,
  isEnableThreeDot: true,
  onClickBackTopNavBar: () => history.back(),
  publisherToken: null as PublishersToken | null,
  user: null as User | null,
  appVersion: "unknown",
  isEnableChallenge: false,
  isEnableTrackingDebug: false,
  assetUrl: "",
  mediaUrl: ""
}

type Callback = () => void

const useAppConfigStore = create(
  devtools(
    combine(initialState, set => {
      return {
        setTitle: (title: string) => set(state => ({ ...state, title })),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        changeState: (key: keyof typeof initialState, value: any) => {
          set({ [key]: value })
        },
        setIsEnableThreeDot: (isEnableThreeDot: boolean) =>
          set(state => ({ ...state, isEnableThreeDot })),
        setOnClickBackTopNavBar: (callback: Callback) => {
          set(state => ({ ...state, onClickBackTopNavBar: callback }))
        },
        resetOnClickBackTopNavBar: () => {
          set(state => ({
            ...state,
            onClickBackTopNavBar: initialState.onClickBackTopNavBar
          }))
        },
        setIsEnableNavbar: (isEnableNavbar: boolean) =>
          set(state => ({ ...state, isEnableNavbar })),
        setPublisherToken: (publisherToken: PublishersToken) =>
          set(state => ({ ...state, publisherToken })),
        setUser: (user: User) => set(state => ({ ...state, user })),
        setAppVersion: (appVersion: string) => set(state => ({ ...state, appVersion })),
        setIsEnableChallenge: (isEnableChallenge: boolean) =>
          set(state => ({ ...state, isEnableChallenge })),
        setIsEnableTrackingDebug: (isEnableTrackingDebug: boolean) =>
          set(state => ({ ...state, isEnableTrackingDebug })),
        setAssetUrl: (assetUrl: string) => set(state => ({ ...state, assetUrl })),
        setMediaUrl: (mediaUrl: string) => set(state => ({ ...state, mediaUrl }))
      }
    })
  )
)

export default useAppConfigStore

import { useEffect, useMemo } from "react"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "@tanstack/react-router"

//components
import CouponCard, { CouponType } from "./CouponCard"
import NoTransaction from "./NoTransaction"

//services
import useFetchMyCoupons from "../-services/useFetchMyCoupon/useFetchMyCoupon"

//utils
import { mapCouponData } from "../-utils/mapCouponData"
import useAppConfigStore from "@/store/useAppConfigStore"

const ReceivedTab = () => {
  const [ref, inView] = useInView()
  const navigate = useNavigate()
  const { data, isLoading, fetchNextPage } = useFetchMyCoupons({})
  const { mediaUrl } = useAppConfigStore()

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  const items = useMemo(() => {
    return (
      data?.pages
        .flatMap(page => page.data.items)
        .map(item => mapCouponData(item, mediaUrl)) ?? []
    )
  }, [data])

  return (
    <>
      {isLoading ? (
        <div
          className="w-full flex justify-center items-center"
          style={{
            height: "calc(100vh - 105px)"
          }}
        >
          <div data-testid="coupons-loading" className="loading" />
        </div>
      ) : (
        <>
          {items.length > 0 ? (
            <>
              <div className="w-full grid grid-cols-2 gap-[15px]">
                {items.map(item => {
                  const productId = `${item.contractAddress}_${item.tokenId}`
                  return (
                    <CouponCard
                      key={item.metadata.id}
                      data={item}
                      type={CouponType.received}
                      onClick={() => navigate({ to: `/coupon/products/${productId}` })}
                    />
                  )
                })}
              </div>
              <div ref={ref} className="h-[1px]" />
            </>
          ) : (
            <NoTransaction variant="received" />
          )}
        </>
      )}
    </>
  )
}

export default ReceivedTab

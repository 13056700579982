import { useEffect, useMemo } from "react"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "@tanstack/react-router"

//components
import CouponCard, { CouponType } from "./CouponCard"
import NoTransaction from "./NoTransaction"

//services
import useFetchMyUsedCoupons from "../-services/useFetchMyUsedCoupon/useFetchMyUsedCoupon"

//utils
import { mapCouponData } from "../-utils/mapCouponData"

//APIs
import { TokenType } from "@/api/coupon.api"
import useAppConfigStore from "@/store/useAppConfigStore"

const UsedTab = () => {
  const [ref, inView] = useInView()
  const navigate = useNavigate()
  const { data, isLoading, fetchNextPage } = useFetchMyUsedCoupons({})
  const { mediaUrl } = useAppConfigStore()

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  const items = useMemo(() => {
    return (
      data?.pages
        .flatMap(page => {
          return page.data.items.map(item => ({
            ...item,
            owner: page.data.owner
          }))
        })
        .map(item =>
          mapCouponData(
            {
              ...item,
              contractAddress: item.contract,
              balance: 0
            },
            mediaUrl
          )
        ) ?? []
    )
  }, [data])

  return (
    <>
      {isLoading ? (
        <div
          className="w-full flex justify-center items-center"
          style={{
            height: "calc(100vh - 105px)"
          }}
        >
          <div data-testid="coupons-loading" className="loading" />
        </div>
      ) : (
        <>
          {items.length > 0 ? (
            <>
              <div className="w-full grid grid-cols-2 gap-[15px]">
                {items.map(item => {
                  const type = item.metadata.type
                  const cashbackId = `${item.contractAddress}_${item.tokenId}`
                  const productId = `${item.contractAddress}_${item.tokenId}_${item.txHash}`
                  return (
                    <CouponCard
                      key={item.metadata.id}
                      data={item}
                      type={CouponType.used}
                      onClick={() =>
                        navigate({
                          to: "/coupon/used-products/$productId",
                          params: {
                            productId: type === TokenType.Deals ? productId : cashbackId
                          }
                        })
                      }
                    />
                  )
                })}
              </div>
              <div ref={ref} className="h-[1px]" />
            </>
          ) : (
            <NoTransaction variant="used" />
          )}
        </>
      )}
    </>
  )
}

export default UsedTab

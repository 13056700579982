import { BrazeToken } from "@/api/common.api"
import * as braze from "@braze/web-sdk"

export const initBraze = (
  { apiKey, restEndpoint }: BrazeToken,
  isEnableTrackingDebug: boolean
) => {
  braze.initialize(apiKey, {
    baseUrl: restEndpoint,
    enableLogging: isEnableTrackingDebug
  })
  return braze
}

export default braze

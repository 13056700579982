import ReactDOM from "react-dom/client"

import { RouterProvider, createRouter } from "@tanstack/react-router"
import { routeTree } from "./routeTree.gen"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import "@/index.css"
import "unfonts.css"
import TopNavbar from "./components/TopNavbar"
import "./utils/faro"
import "core-js/features/object/has-own"
const queryClient = new QueryClient()

// Set up a Router instance
const router = createRouter({
  routeTree: routeTree,
  context: {
    queryClient
  },
  // defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0
})

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
  interface HistoryState {
    redemption?: boolean
    trackCode?: string
    trackMessage?: string
  }
}

const rootElement = document.getElementById("root")!

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <QueryClientProvider client={queryClient}>
      <TopNavbar />
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}

import { useEffect } from "react"
import { createLazyFileRoute, useNavigate } from "@tanstack/react-router"
import { useTranslation } from "react-i18next"

//components
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import ReceivedTab from "./-components/ReceivedTab"
import UsedTab from "./-components/UsedTab"
import ExpiredTab from "./-components/ExpiredTab"

//stores
import useAppConfigStore from "@/store/useAppConfigStore"
import { trackTMNCoinClickButton } from "@/api/tracking.api"

//utils
import { TypeofValidateSearch } from "./index"

//APIs
import { trackViewCouponsPage } from "@/api/tracking.api"

export const Route = createLazyFileRoute("/_main-layout/coupon")({
  component: RouteComponent
})

export enum Tab {
  received = "Received",
  used = "Used",
  expired = "Expired"
}

function RouteComponent() {
  const { t } = useTranslation(["coupon"])
  const qs: TypeofValidateSearch = Route.useSearch()
  const navigate = useNavigate()

  const { setTitle, setIsEnableThreeDot } = useAppConfigStore()

  useEffect(() => {
    setTitle(t("titlePage"))
    setIsEnableThreeDot(false)
    return () => {
      setIsEnableThreeDot(true)
    }
  }, [])

  useEffect(() => {
    trackViewCouponsPage(qs.type)
  }, [qs.type])

  return (
    <div>
      <Tabs
        data-testid="my-coupons-tabs"
        defaultValue={Tab.received}
        value={qs.type ? qs.type : Tab.received}
        className="w-full h-[54px]"
        onValueChange={value => {
          navigate({ search: { type: value } })
        }}
      >
        <TabsList
          data-testid="my-coupons-tabslist"
          className="grid w-full grid-cols-3 h-full"
        >
          <TabsTrigger
            onClick={() => {
              trackTMNCoinClickButton({
                buttonName: "My coupon - valid"
              })
            }}
            data-testid="coupons-received-tab"
            value={Tab.received}
          >
            {t("receivedLabel")}
          </TabsTrigger>
          <TabsTrigger
            onClick={() => {
              trackTMNCoinClickButton({
                buttonName: "My coupon - used"
              })
            }}
            data-testid="coupons-used-tab"
            value={Tab.used}
          >
            {t("usedLabel")}
          </TabsTrigger>
          <TabsTrigger
            onClick={() => {
              trackTMNCoinClickButton({
                buttonName: "My coupon - expired"
              })
            }}
            data-testid="coupons-expired-tab"
            value={Tab.expired}
          >
            {t("expiredLabel")}
          </TabsTrigger>
        </TabsList>
        <TabsContent className="py-6 px-4" value={Tab.received}>
          <ReceivedTab />
        </TabsContent>
        <TabsContent className="py-6 px-4" value={Tab.used}>
          <UsedTab />
        </TabsContent>
        <TabsContent className="py-6 px-4" value={Tab.expired}>
          <ExpiredTab />
        </TabsContent>
      </Tabs>
    </div>
  )
}

import { createFileRoute } from "@tanstack/react-router"
import BadgeIcon from "@/assets/icons/ic_badge.svg?react"
import { Challenge } from "@/api/challenge.api"
import useAppConfigStore from "@/store/useAppConfigStore"
import { useMemo } from "react"

export const Route = createFileRoute(
  "/_main-layout/challenge-detail/$challengeDetailId/~components/missionCard"
)({
  component: () => <div>Hello MissionCard!</div>
})

import { Button } from "@/components/ui/button"

const ChallengeMissionCard = ({ challenge }: { challenge: Challenge }) => {
  const { assetUrl } = useAppConfigStore()
  const missions = challenge?.tasks?.[0]
  const { totalTasks, completedTasks, totalProgress } = useMemo(() => {
    const totalTasks = challenge?.tasks?.length || 0
    const completedTasks = challenge?.tasks?.filter(task => task.completed).length
    const totalProgress = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0

    return { totalTasks, completedTasks, totalProgress }
  }, [challenge?.tasks])
  
  // const isClaimable = completedTasks > 0
  // const isCompleted = totalTasks > 0 && completedTasks === totalTasks
  return (
    <div className="relative w-auto overflow-hidden m-4">
      <div className="p-4 items-center rounded-lg bg-white border-t border-gray-200">
        <div className="flex">
          <div className="flex-shrink-0">
            <div className="w-[72px] h-[72px] bg-gray-200 rounded-lg flex items-center justify-center border border-gray-300">
              <img
                src={`${assetUrl}${challenge?.campaign?.image}`}
                className="w-full h-full rounded-full"
              />
            </div>
          </div>
          <div className="flex flex-col items-end ml-5">
            <p className="text-sm font-semibold text-[#212B36] leading-6 font-['IBM Plex Sans Thai', sans-serif]">
              {missions?.task.name}
            </p>
          </div>
        </div>

        <div className="flex items-center my-4">
          <div className="w-full bg-gray-200 rounded-full h-1">
            <div
              className={`h-full rounded-full ${totalProgress > 0 ? "bg-blue-600" : "bg-gray-400"}`}
              style={{ width: `${totalProgress}%` }}
            ></div>
          </div>
          <span className="ml-2 text-caption text-sm font-semibold text-[#919EAB] leading-6 font-['IBM Plex Sans Thai', sans-serif]">
            {completedTasks}/{totalTasks}
          </span>
        </div>
        <div className="flex  justify-between">
          {/* Voucher Text in the Bottom Left */}
          <span className="flex items-center space-x-1 text-sm text-gray-500">
            <BadgeIcon className="w-full h-full" />
            <span>Badge</span>
          </span>

          {/* Button on the right */}
          <Button
            size="sm"
            className="border border-yellow-500 text-yellow-500 bg-transparent hover:bg-gray-100 space-x-1 rounded-lg"
          >
            Play now
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChallengeMissionCard

import { useState } from "react"
import { createFileRoute } from "@tanstack/react-router"
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover"
import StarIcon from "@/assets/icons/orange-star.svg?react"
import TrophyIcon from "@/assets/icons/ic_trophy.svg?react"
import CalendarIcon from "@/assets/icons/ic_info.svg?react"
import Divider from "@/components/Divider"

export const Route = createFileRoute(
  "/_main-layout/challenge-detail/$challengeDetailId/~components/howToPlayCard"
)({
  component: () => <HowToPlayPopover />
})

const HowToPlayPopover = () => {
  const [isOpen, setIsOpen] = useState(false)

  const togglePopover = () => setIsOpen(!isOpen)

  return (
    <Popover open={isOpen} onOpenChange={togglePopover}>
      {/* Popover Trigger */}
      <PopoverTrigger asChild>
        <span className="text-orange-600 cursor-pointer" onClick={togglePopover}>
          How to play
        </span>
      </PopoverTrigger>

      {/* Popover Content */}
      {isOpen && (
        <PopoverContent className="relative mt-[-35vh] inset-0 flex items-center justify-center min-h-screen bg-gray-800 bg-opacity-80">
          <div className="w-full max-w-lg p-6 bg-white shadow-lg rounded-lg overflow-y-auto md:max-w-2xl max-h-full relative">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
              onClick={togglePopover}
            >
              &times;
            </button>

            {/* How to Play Title */}
            <h2 className="text-xl font-semibold mb-4">How to play</h2>

            {/* Instructions List */}
            <ol className="list-decimal pl-5 space-y-2 my-4 text-sm text-gray-700">
              <li>
                Learn basic skills in the first tutorial mission. It will instruct you how
                to move and switch characters, fire weapons, drive, use maps, and more.
              </li>
              <li>
                Take missions at your own pace after the tutorial. Switch characters,
                locate missions on the map, and play side missions to explore all of Los
                Santos.
              </li>
              <li>Finish story mode by completing all missions.</li>
            </ol>
            <Divider />
            {/* Event Duration */}
            <div className="my-4 flex items-center space-x-2 text-sm text-gray-500">
              <CalendarIcon className="w-5 h-5" />
              <span>15 Jan 2023 00:00 - 15 Feb 2023 23:00</span>
            </div>
            <Divider />

            {/* Rewards Section */}
            <div className="my-4">
              <h3 className="font-semibold text-lg mb-2">Rewards</h3>
              <ul className="space-y-2 text-sm text-gray-700">
                <li className="flex items-center space-x-2">
                  <StarIcon className="w-5 h-5 text-gray-500" />
                  <span>Mission reward name 1</span>
                </li>
                <li className="flex items-center space-x-2">
                  <StarIcon className="w-5 h-5 text-gray-500" />
                  <span>Mission reward name 2</span>
                </li>
                <li className="flex items-center space-x-2">
                  <TrophyIcon className="w-5 h-5 text-gray-500" />
                  <span>Challenge reward</span>
                </li>
              </ul>
            </div>
            <Divider />

            {/* Terms & Conditions */}
            <div className="mt-4">
              <h3 className="font-semibold text-lg mb-2">Terms & Conditions</h3>
              <p className="text-sm text-gray-500 leading-relaxed">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lacinia neque
                faucibus nulla finibus lacinia. Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Nam lacinia neque faucibus
                nulla finibus lacinia. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Nam lacinia neque faucibus nulla
                finibus lacinia. Lorem ipsum dolor sit amet.
              </p>
            </div>
          </div>
        </PopoverContent>
      )}
    </Popover>
  )
}

export default HowToPlayPopover

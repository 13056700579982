import { useEffect, useMemo } from "react"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "@tanstack/react-router"

//components
import CouponCard, { CouponType } from "./CouponCard"
import NoTransaction from "./NoTransaction"

//services
import useFetchMyExpiredCoupons from "../-services/useFetchMyExpiredCoupon/useFetchMyExpiredCoupon"

//utils
import { mapCouponData } from "../-utils/mapCouponData"
import useAppConfigStore from "@/store/useAppConfigStore"

const ExpiredTab = () => {
  const navigate = useNavigate()
  const [ref, inView] = useInView()
  const { data, fetchNextPage } = useFetchMyExpiredCoupons({})
  const { mediaUrl } = useAppConfigStore()

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  const items = useMemo(() => {
    return (
      data?.pages
        .flatMap(page => page.data.items)
        .map(item => mapCouponData(item, mediaUrl)) ?? []
    )
  }, [data])

  return (
    <>
      {items.length > 0 ? (
        <>
          <div className="w-full grid grid-cols-2 gap-[15px]">
            {items.map(item => {
              const productId = `${item.contractAddress}_${item.tokenId}`
              return (
                <CouponCard
                  key={item.metadata.id}
                  data={item}
                  type={CouponType.expired}
                  onClick={() => navigate({ to: `/coupon/products/${productId}` })}
                />
              )
            })}
          </div>
          <div ref={ref} className="h-[1px]" />
        </>
      ) : (
        <NoTransaction variant="expired" />
      )}
    </>
  )
}

export default ExpiredTab

import mixpanel, { OverridedMixpanel } from "mixpanel-browser"

export const initMixpanel = (
  token: string,
  isEnableTrackingDebug: boolean
): OverridedMixpanel => {
  mixpanel.init(token, {
    debug: isEnableTrackingDebug
  })
  return mixpanel
}

export default mixpanel
